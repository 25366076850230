body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* background-image: url(../src/components/container/bakgrund/bakgrund_ny.png); */
 /* background-color: #f3bedf !important; */
  /* background-color: rgb(41, 110, 41)!important; */
    /* background: linear-gradient(#367225, #e0a606) !important; */
    /* background-color: #367225 !important; */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
