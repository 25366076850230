.clear {
    clear: both;
}

.col-form-label {
    font-size: 20px !important;
    text-align: left !important;
    font-family: "Roboto" !important;
}

.checkoutForm {
    text-align: left;
}

.checkoutDiv {
    text-align: left;
    margin-left: 35px;
    margin-top: 35px;
    margin-right: 15px;
    margin-bottom: 15px;
    font-family: "Roboto";
}

.condiciones {
    font-size: 15px;
    color: black;
    font-family: "Roboto";
    margin-bottom: 30px !important;
    font-weight: 700 !important; 
    justify-content: flex-end;
    display: -webkit-box;
    margin-left: 45px;
    margin-top: 25px;    
}

.divCondiciones {
    float:right;
}

.bolder {
    font-weight: bold;
}