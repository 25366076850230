.cartName {
    text-align: left;
} 

.hiddenRow {
    display: none !important;
}

.nopadding {
    padding-left: 0px !important;
    padding-right: 0px !important;
    text-align: center;
}

.clear {
    clear: both;
}

.knapp {    
    float: left;
}

.noknapp {
    margin-left: 2px;
    margin-right: 2px;
    float: left;
}

@media screen and (min-width: 900px) {
.cartHeaderName {
    font-weight: bold;
    background: linear-gradient(#367225, #e0a606);
    box-shadow: 0px 8px 8px rgb(65, 64, 64);
    text-align: left;
    font-family: "Chicle" !important;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 25px !important;
}

.cartHeader {
    font-family: "Chicle" !important;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 25px !important;
    background: linear-gradient(#367225, #e0a606);
    box-shadow: 0px 8px 8px rgb(65, 64, 64);
}

.knapp {
    width: 20px !important;
    margin-bottom: 10px;
    padding: 3px 3px 3px 3px;
    background: linear-gradient(#367225, #e0a606);
    border: 1px solid #000;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    float: left;
    cursor: pointer;
}

.noknapp {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    float: left;
}

}

.white-space {
    min-height: calc(100vh - 300px);
}


.checkout {
    color: black !important;
    background: linear-gradient(#367225, #e0a606);
    font-family: "Chicle";
    margin-bottom: 20px;
    box-shadow: 8px 8px 8px rgb(65, 64, 64);
    font-size: 1rem;   
    border: 2px solid rgba(15, 78, 26, 0.726) !important;
    border-radius: 5px 5px 5px 5px;
    padding: 10px 10px;
    float: right;
}