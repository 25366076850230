.legal {
    text-align: left;
}

.head {
    font-family: "Chicle";
    text-align: left;
    margin-left: 55px;


}

.head2 {
    font-family: "Chicle";
    text-align: left;
    margin-left: 55px;

}

.text0 {
    font-family: "Roboto";
    text-align: left;
    margin-left: 10px !important;
    margin-right: 10px !important;
    font-weight: 600;
}