.root {
  padding-bottom:60px !important;
}

.main-container {
  margin-bottom: 140px;
}

@media screen and (max-width: 900px) {
	#searchForm {   
    display: none;
	}	

  .desktopItem {
    display: none !important;
  }

  .statusbar {
    display: none !important;   
  }
}

@media screen and (min-width: 900px) {
	
  .mobilemenu {
    display: none !important;
  }
  
  .navbar-wrapper {
    /* display: none !important; */
  }

  .statusbar {
    padding-top: 3px;
    background-color: #367225;
    font-size: 20px;
    color: whitesmoke;   
    height: 40px;
  }

  .jelly {
    font-size: 54px !important;
    line-height: 30px !important;
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    height: 90px !important;
    border-radius: 100px;
    line-height: 15px;
    font-size: 35px;
    font-weight: 600;	
    cursor: pointer;
    animation-duration: 5s;
    transform: translatey(0px);
    animation: float 4s ease-in-out infinite;
  }
  
  .jelly-one {
    font-size: 54px !important;
    line-height: 30px !important;
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    height: 90px !important;
    border-radius: 100px;
    line-height: 15px;
    font-size: 35px;
    font-weight: 600;	
    cursor: pointer;
    animation-duration: 5s;
    transform: translatey(0px);
    animation: float 7s ease-in-out infinite;
  }
  
  .jelly-two {
    font-size: 54px !important;
    line-height: 30px !important;
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    height: 90px !important;
    border-radius: 100px;
    line-height: 15px;
    font-size: 35px;
    font-weight: 600;	
    cursor: pointer;
    animation-duration: 5s;
    transform: translatey(0px);
    animation: float 4s ease-in-out infinite;
  }
  
  .jelly-three {
    font-size: 54px !important;
    line-height: 30px !important;
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    height: 90px !important;
    border-radius: 100px;
    line-height: 15px;
    font-size: 35px;
    font-weight: 600;	
    cursor: pointer;
    animation-duration: 5s;
    transform: translatey(0px);
    animation: float 7s ease-in-out infinite;
  }
  
  .jelly-four {
    font-size: 54px !important;
    line-height: 30px !important;
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    height: 90px !important;
    border-radius: 100px;
    line-height: 15px;
    font-size: 35px;
    font-weight: 600;	
    cursor: pointer;
    animation-duration: 5s;
    transform: translatey(0px);
    animation: float 4s ease-in-out infinite;
  }
    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
       transform: translatey(0px);
      }
    }
  
    .jelly-knapp {
      justify-content: flex-end;
      display: inline-flex ;
    }  
}

.App {  
  text-align: center; 
  padding: 0px !important;
} 

.form {
  position: absolute;
  right: 55px;
  top: 55px;
}  

.App-logo {
  width: 80%;
  pointer-events: none;
  margin-top: 25px;
  margin-bottom: 55px;
}

.App-link {
  color: #fb61ae;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  /* background: linear-gradient(#1d0b14, #83365a, #c92188, #6d7c9b, #65cce6, #3db443, #dde970,  #ee7c47); */
  /* background-color: #f3bedf; */

}

.buscar {
  color: black !important;
  background: linear-gradient(#367225, #e0a606);
  font-family: "Chicle";
  height: 38px;
  width: 65px;
  margin-top: 11px;
  margin-bottom: 10px;
  box-shadow: 8px 8px 8px rgb(65, 64, 64);
  font-size: 20px;   
  border: 2px solid rgba(15, 78, 26, 0.726) !important;
  border-radius: 5px;
}

.card-img-top {
  max-height: 200px;

}

.cardImage {
  min-height: 200px;
  max-height: 200px !important;
  border-bottom: 3px solid rgb(240, 172, 46);
  border-top-left-radius: 25px !important;    
  border-top-right-radius: 25px !important;    
}

.card {
  border-bottom-right-radius: 25px !important;
  border-bottom-left-radius: 25px !important;    
  border-top-left-radius: 25px !important;    
  border-top-right-radius: 25px !important;   
  box-shadow: 8px 8px 8px rgb(65, 64, 64);
  width: 200px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto !important;
  border: #367225 5px solid !important;

}

.cardframe_frontpage {
  /* margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 25px;
  float: left; */
}

.card-title {
  font-size: 30px !important;
  font-family: "Chicle";
  color: #367225;
  text-shadow: 2px 2px  rgb(65, 64, 64);
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
}

.btn {
  color: black !important;
  background: linear-gradient(#367225, #e0a606);
  font-family: "Chicle";
  margin-bottom: 10px;
  box-shadow: 4px 4px 4px rgb(65, 64, 64);
  
  border: 2px solid rgba(15, 78, 26, 0.726) !important;
 }

.card-body {
  font-family: "Chicle";
  font-size: 15px;
  min-width: 150px; 
  padding: 0 !important;
  height: 50px;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important; 
  background: rgb(255,197,120);
  background: -moz-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%, rgba(251,157,35,1) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
  background: radial-gradient(ellipse at center,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=1 );
}

.body-listingClass {
  font-family: "Chicle";
  min-width: 150px; 
  padding-bottom: 0px !important;
  height: 100px;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important; 
  background: rgb(255,197,120);
  background: -moz-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%, rgba(251,157,35,1) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
  background: radial-gradient(ellipse at center,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=1 );
}

.cardframe {
  /* margin-right: 25px; */
  /* margin-bottom: 40px !important; */
  box-shadow: 8px 10px 8px rgb(65, 64, 64);
  height: 300px; /* Magic here */
  display: flex;
}

.cardframe-productListingClass {
  /* margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 40px !important;
  box-shadow: 8px 10px 8px rgb(65, 64, 64); */
}
.card-footer {
  background-color: rgb(240, 172, 46) !important;
}


.linespacing {
  line-height: 40px;
}