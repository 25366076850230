  .homeCategoryListing .categoryListing{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
 .apertura {
    margin-top: 10px;
    font-family: "Chicle";
    font-size: 3rem;
    font-weight: lighter;
    color: #367225;
    text-shadow: 2px 2px  rgba(5, 5, 5, 0.973);
    
  } 
  
  .hours {
    font-family: "Chicle";
    font-size: 2rem;
    font-weight: lighter;
    color: #367225;
    text-shadow: 2px 2px  rgba(5, 5, 5, 0.973);
    justify-content: flex-end;
    display:inline-flex;  
    margin-bottom: 25px;
    
  }  
 
  .phrase1 {
    font-family: "Chicle";
    font-size: 55px;
    font-weight: bolder;
    color: #367225;
    /* color: #479731; */
    /* color: #479731; */
    margin-top: 20px;
    text-shadow: 2px 3px  rgba(5, 5, 5, 0.973);
  }

   .phraseText {
    font-family: "Roboto";
    font-size: 25px;
    font-weight: 700 !important;
    color: black ;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: 500;
  }

  .vegano {
    font-family: "Chicle";
  }

  .reposteria {
    font-family: "Roboto";
    font-size: 25px;
   }

  .empresa {
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 700 !important;

  }
  
  .bild {
    /* background: rgb(255,197,120);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%, rgba(251,157,35,1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=1 ); */
    background-image: url(../container/bakgrund/bakgrund_suddig.jpg);
    box-shadow: 8px 8px 8px rgb(65, 64, 64);
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important; 
    border-color: whitesmoke; 
    border-style: solid;
    border-width: 3px; 
    width: 50% !important; 
    justify-content: flex-end;
    display: inline-table; 
    margin-bottom: 100px;  
    
  } 

  .suscribirse {
    font-family: "chicle";
    font-weight: 700 !important;
    color: black;
    font-size: 45px;
    margin-top: 15px;
    padding-top: 15px;    
  }

  .suscribirseText {
    font-family: "Roboto";
    font-weight: 700 !important;
    font-size: 20px;
    color: black;
  }

  .linespacing2 {
    line-height: 15px;
    margin-bottom: 45px;
  }

  .inputContact {
   margin-bottom: 25px;
   font-family: "Roboto";   
  }

  .subscribir {
    margin-bottom: 40px !important;
    font-size: 25px !important;
  }

  .horarios {
    background: rgb(255,197,120);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%, rgba(251,157,35,1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F99DBC', endColorstr='#F5347F',GradientType=1 );
    box-shadow: 8px 8px 8px rgb(65, 64, 64);
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important; 
    border-color: whitesmoke; 
    border-style: solid;
    border-width: 3px; 
    justify-content: flex-end;
    display: inline-table;
    margin-bottom: 25px;
    margin-top: 50px;   
}

  /*
  .horarios {
    background: rgb(255,197,120);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%, rgba(251,157,35,1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=1 );
    box-shadow: 8px 8px 8px rgb(65, 64, 64);
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important; 
    border-color: whitesmoke; 
    border-style: solid;
    border-width: 3px; 
    justify-content: flex-end;
    display: inline-table;
    margin-bottom: 25px;
    margin-top: 50px;   
}*/
  

 

  
  
  
  
 
  
  
  
  
  
  

 
  
  

