.galleryCard {
  height: 230px;
  border-top-left-radius: 25px !important;    
  border-top-right-radius: 25px !important; 
  padding-top: 10px;
  border: #367225 5px solid !important;
  
  
}

.galleryCards {
    border-top-left-radius: 30px !important;    
    border-top-right-radius: 30px !important; 
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

