
.navbar-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    
}

.nav-item {
    font-family: "Chicle" !important;
    font-weight: normal !important;
    font-size: 35px;  
    /* padding-right: 35px !important;
    padding-left: 75px; */
    margin-right: 25px;
}

.navbar-light .navbar-nav .nav-link {
    /* color: black !important; */
    /* background-color: linear-gradient( #5f0d50 50%, #3d1934 100%); */
    /* color: rgb(151, 5, 107) !important; */
    color: #367225 !important;

    /* text-shadow: 5px 5px  rgba(238, 222, 222, 0.973); */
    text-shadow: 2px 3px  rgba(5, 5, 5, 0.973);
    /* margin-left: 55px; */
    
}

.wordSpacing {
    word-spacing: 50px !important;
}

.cart {
    height: 50px;
    padding-top: 15px;
    margin-left: 55px;
    margin-right: 0;
}

