.footerHome {
    background: linear-gradient(#367225, #e0a606);
    min-height: 60px;
    width: 100%;
    font-family: "Chicle";
    font-size: 25px;
    position: fixed; 
    bottom:0px;  
    margin-bottom: 0px;
    margin-top: 120px;
  }

  @media (max-width: 600px) {
    .footerHome {
      /* display: none; */
    }
    .other {
      font-size: 1rem !important;
      color: black;  
      font-family: "Chicle";
    }
    .footerHome {
      font-size: 1rem !important;      
    }
  }

  .adress {
    font-family: "Chicle";
    color: black;
    font-size: 1rem;
  }

.footerHeight {
    padding-top: 15px;
}

  .FB {
    max-height: 40px;
    margin-bottom: 15px;
    float: right;
    margin-right: 15px;
  }

  .insta {
    max-height: 40px;
    margin-bottom: 15px;
    float: right;
    margin-right: 15px;
  }

  .other {
    font-size: 25px;
    color: black;  
    font-family: "Chicle";
  }

  .egen {
    font-size: 25px;
    color: black;
   }

   .footerNew {
     padding-left: 10px;
     margin-left: 0px;
     margin-right: 0px;
   }
  
  