.pedir {
    font-size: 25px !important;
    color: black !important;
    background: linear-gradient(#367225, #e0a606);
    font-family: "Chicle";
    margin-top: 25px;
    margin-bottom: 100px;
    box-shadow: 8px 8px 8px rgb(65, 64, 64);
    font-size: 50px;   
    border: 2px solid rgba(15, 78, 26, 0.726) !important;
    border-radius: 5px;
} 

.productClassPrice {
    font-size: 50px;
    font-weight: bold;
    font-family:"Chicle";
    color: #367225;
    text-shadow: 2px 2px  rgb(65, 64, 64);
}

.leftDiv {
    float: left;
}

.rightDiv {
    float: left;
}

.imgDiv {
    margin-bottom: 25px;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    margin-left: 100px !important;
}

.information {
    margin-top: 15px;
    font-size: 1rem;
    color: black;
    font-family: "Roboto";
    font-weight: bold !important;
    background: rgb(255,197,120);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%, rgba(251,157,35,1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=1 );
    box-shadow: 8px 8px 8px rgb(65, 64, 64);
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important; 
    border-color: whitesmoke; 
    border-style: solid;
    border-width: 3px; 
    width: 70% !important; 
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: flex-end;
    display: inline-table; 
    margin-bottom: 25px;
}

.name {
    font-size: 3rem;
    font-weight: medium;
    color: #367225;
    margin-top: 25px;
    font-family: "Chicle";
    text-shadow: 2px 3px  rgba(5, 5, 5, 0.973);

}

.image {
    width: 80%;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 8px 8px 8px rgb(65, 64, 64);
    border: #367225 5px solid;
}

.tamaño {
   color: #367225;
   text-shadow: 2px 3px  rgba(5, 5, 5, 0.973);
   font-family: "Chicle";
   font-size: 35px;
}

.info-tarta {
    font-size: 20px;
}

.precio {
    font-size: 25px;
}

/* .tamaño-group {
   float: left;
   display: grid;
} */

.button-info {
    padding-bottom: 15px !important;
    height: 55px;
    max-width: 400px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    color: black !important;
  background: linear-gradient(#367225, #e0a606);
  font-family: "Chicle";
  margin-bottom: 10px;
  box-shadow: 8px 8px 8px rgb(65, 64, 64);
  
  border: 2px solid rgba(15, 78, 26, 0.726) !important;
}






