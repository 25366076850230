.contacto {
    font-size: 50px;
    font-family: "Chicle";
    color: #367225;
    margin-top: 5px;
    margin-bottom: 15px;
    text-shadow: 2px 3px  rgba(5, 5, 5, 0.973);
    text-align: center !important;
}

.contacto-other {
    font-size: 40px;
    font-family: "Chicle";
    color: #367225;
    margin-top: 5px;
    margin-bottom: 15px;
    text-shadow: 2px 3px  rgba(5, 5, 5, 0.973);
    text-align: center;
}

.list {
    font-family: "Roboto";
    color: black;
    font-size: 1rem;
    text-align: left;
    font-weight: 700 !important;

}

.inscribirse {
    font-size: 15px;
    color: black;
    font-family: "Roboto";
    margin-bottom: 30px !important;
    font-weight: 700 !important;  

}

.enviar {
    color: black !important;
    background-color: #87A96B !important;
    font-weight: thin !important;
    border-color: #87A96B !important;
    font-family: "Chicle";
    font-size: 25px !important;
    margin-bottom: 45px;
    margin-top: 15px;
}

.form-group {
    margin-bottom: 0px !important;
}

.contact-form {
    background: rgb(255,197,120);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%, rgba(251,157,35,1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,197,120,1) 0%,rgba(251,157,35,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=1 );
    box-shadow: 8px 8px 8px rgb(65, 64, 64);
    border-bottom-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important; 
    border-color: whitesmoke; 
    border-style: solid;
    border-width: 3px;
    justify-content: flex-end;
    display: inline-table;
    padding-top: 25px;
    margin-bottom: 20px;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
}
